.planner_menu {
  height: 85vh;
  overflow-y: scroll;
  background-color: #FFF;
}

.planner_map {
  height: 85vh;
}

/* width */
.planner_menu::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.planner_menu::-webkit-scrollbar-track {
  background: #cecece;
}

/* Handle */
.planner_menu::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.planner_menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}