path.leaflet-line {
  stroke: #50622b;
  /* stroke: gold; */
  stroke-width: 2;  
}

div.leaflet-edge {
  max-width: 8px !important;
  max-height: 8px !important;
  background-color: #95bc59;
  /* background-color: gold; */
  box-shadow: 0 0 0 2px white, 0 0 10px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  cursor: move;
  outline: none;
  transition: background-color 0.25s;
}

div.leaflet-edge.disabled {
  pointer-events: none;
  background-color: #bbb;
}

path.leaflet-polygon {
  fill: #b4cd8a;
  stroke: #50622b;
  /* fill: gold; */
  /* stroke: gold; */
  stroke-width: 1;
  /* fill-opacity: 1; */
  fill-opacity: 0.35;  
}

.map.mode-create {
  cursor: crosshair;
}
