@import './styles/variables'; // modify bootstrap variables and put extra variables in this file
@import './node_modules/bootstrap/scss/bootstrap.scss'; // import full bootstrap here (as-is)

/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ubuntu-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
    url('/fonts/ubuntu-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/ubuntu-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/ubuntu-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/ubuntu-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/ubuntu-v14-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/ubuntu-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
    url('/fonts/ubuntu-v14-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/ubuntu-v14-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/ubuntu-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/ubuntu-v14-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/ubuntu-v14-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

// ---------- bootstrap override ------------
// ------------------------------------------
html {
  font-family: 'Ubuntu';
  font-size: $font-size-root;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  letter-spacing: 0.045rem;
}
*:focus {
  outline: none !important;
}
a {
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}
hr {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}
img {
  max-width: 100%;
}
code {
  font-size: 80%;
  background: $md-deep-orange-50;
  color: $md-brown-800;
}
button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-sans-serif;
}

/** Bug fix for pre **/
// @media (max-width: 575px) {
//     .container {
//         max-width: 100%;
//     }
// }

// ------ buttons
.btn-primary {
  background-color: $brand-info;
  border-color: $brand-info;
  &:hover {
    background-color: $brand-second-b;
    border-color: $brand-second-b;
  }
  &:disabled {
    background-color: $brand-second-b;
    border-color: $brand-second-b;
  }
  &:focus {
    background-color: $brand-second-b !important; 
    border-color: $brand-second-b !important;
  }
  &:active {
    background-color: $brand-second-b !important; 
    border-color: $brand-second-b !important;
  }
  &.active, .focus {
    background-color: $brand-second-b !important;
    border-color: $brand-second-b !important;
  }
}

.btn-light {
  background-color: $brand-fifth;
  border-color: $brand-fifth;
  color: $brand-primary;
  &:hover {
    background-color: $brand-fifth;
    border-color: $brand-fifth;
  }
  &:disabled {
    background-color: $brand-fifth-b;
    border-color: $brand-fifth-b;
  }
}

.btn-outline-light {
  color: $brand-fifth;
  background-color: transparent;
  background-image: none;
  border-color: $brand-fifth;
  &:hover {
    background-color: $brand-fifth;
    border-color: $brand-fifth;
    color: $brand-primary;
  }
}

.badge-info {
  background-color: $brand-info;
}

.filter-picker-value {
  background-color: $brand-fifth;
  border-color: $brand-fifth;
  color: $brand-primary !important;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 5px 11px;
  cursor: pointer;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background-color: $brand-fifth;
  border-color: $brand-fifth;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  background-color: $brand-fifth;
  border-color: $brand-fifth;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: $brand-primary !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $brand-primary !important;
}
.rs-picker-default .rs-picker-toggle,
.rs-picker-default .rs-picker-toggle-custom {
  color: $brand-primary !important;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  border-color: $brand-info;
  background-color: $brand-info;
}

.rs-picker-toolbar-right-btn-ok {
  border-color: $brand-info;
  background-color: $brand-info;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date,
.rs-calendar-month-dropdown-year-active {
  color: $brand-info;
}

.btn {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.05);
  &:focus {
    box-shadow: none !important;
  }
}
.btn-fab {
  border-radius: 50%;
  min-width: 44px;
  min-height: 44px;
  padding: 0;
}
.btn-fab.btn-sm {
  min-width: 32px;
  min-height: 32px;
}
.btn-fab.btn-xs {
  min-width: 16px;
  min-height: 16px;
  line-height: 0;
}
.btn-fab.btn-lg {
  min-width: 56px;
  min-height: 56px;
}

.btn-light-gray {
  color: #212529;
  background-color: #dee2e6bd;
  border-color: #dee2e6bd;
}

// ----- breadcrumb
.breadcrumb-item.active {
  font-weight: 700;
}

// ----- dropdowns
.dropdown > .dropdown-menu {
  transition: 0.2s ease-in-out;
  transform: scale(0.8);
  transform-origin: 100% 0;
  display: block;
  visibility: hidden;
  opacity: 0;
}
.dropdown.show > .dropdown-menu {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  z-index: 99999;
}
.dropdown-menu {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
}
.dropdown-item {
  padding: 7px $dropdown-item-padding-x;
  a {
    color: $body-color;
  }
}

// ----- cards
.card {
  overflow: hidden;
  // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
  border: 0px solid #eceff1;
}
.card-inverse {
  color: rgba(255, 255, 255, 0.95);
}

// ----- utilities
.font-weight-semi-bold {
  font-weight: 600;
}

// ---- reactable header
.reactable-header-sortable > span {
  display: inline-block;
  position: relative;
  &::after,
  &::before {
    content: '';
    position: absolute;
    border: 4px solid transparent;
    right: -1.2rem;
  }
  &::after {
    border-color: $md-grey-500 transparent transparent transparent;
    top: 11px;
  }
  &::before {
    border-color: transparent transparent $md-grey-500 transparent;
    top: 1px;
  }
}
// hide one of the triangle on sort.
.reactable-header-sort-asc.reactable-header-sortable > span::before,
.reactable-header-sort-desc.reactable-header-sortable > span::after {
  border-color: transparent;
}

// ---- Reactable pagination
.reactable-pagination {
  .reactable-page-button,
  .reactable-next-page,
  .reactable-previous-page {
    display: inline-block;
    cursor: pointer;
    padding: 0.35rem 0.9rem;
    font-size: 12px;
    border: 1px solid $md-grey-200;
    border-radius: 2px;
    margin-right: 4px;
  }
  .reactable-current-page {
    background: $md-blue-500;
    color: #fff;
  }
  .reactable-next-page,
  .reactable-previous-page {
  }
}

// Modals Transition
// -----------------
// Because we can't add extra class directly in `.modal` through reactstrap react lib.
// We attached it to `.modal-dialog` and controlled it via `.fade` and `.show` class of `.modal`
.modal {
  .modal-dialog {
    margin-top: 8rem;
  }
  .modal-header .modal-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
  &.fade {
    perspective: 1300; // for 3d flip effect
    .modalFadeInScale,
    .modalSlideIn,
    .modal3DFlipHorizontal,
    .modal3DFlipVertical,
    .modalFall,
    .modalRapid {
      &.modal-dialog {
        transition: 0.3s;
      }
    }
  }
  &.show {
    .modalFadeInScale,
    .modalSlideIn,
    .modal3DFlipHorizontal,
    .modal3DFlipVertical,
    .modalFall,
    .modalRapid {
      &.modal-dialog {
        opacity: 1;
      }
    }
  }

  // === fadeInScale
  .modalFadeInScale.modal-dialog {
    transform: scale(0.7);
  }
  &.show .modalFadeInScale.modal-dialog {
    transform: scale(1);
    -webkit-font-smoothing: subpixel-antialiased;
  }

  // ==== slideIn
  .modalSlideIn.modal-dialog {
    transform: translate(20%, 0);
  }
  &.show .modalSlideIn.modal-dialog {
    transform: translate(0, 0);
  }

  // === 3D Flip (Horizontal)
  .modal3DFlipHorizontal.modal-dialog {
    transform: rotateY(-70deg);
  }
  &.show .modal3DFlipHorizontal.modal-dialog {
    transform: rotateY(0);
    transform-style: preserve-3d;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  // === 3D Flip (Vertical)
  .modal3DFlipVertical.modal-dialog {
    transform: rotateX(-70deg);
  }
  &.show .modal3DFlipVertical.modal-dialog {
    transform: rotateX(0);
    transform-style: preserve-3d;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  // === fall
  .modalFall.modal-dialog {
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
  }
  &.show .modalFall.modal-dialog {
    transform: translateZ(0px) rotateX(0deg);
    -webkit-font-smoothing: subpixel-antialiased;
  }

  // === Fast Train
  &.show .modal-dialog.modalRapid {
    animation: modalRapid 0.5s forwards ease-out;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  @-webkit-keyframes modalRapid {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(calc(-100vw - 50%), 0, 0);
    }
    50% {
      opacity: 1;
      -webkit-transform: translate3d(100px, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
    }
  }

  @keyframes modalRapid {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(calc(-100vw - 50%), 0, 0);
      transform: translate3d(calc(-100vw - 50%), 0, 0);
    }
    50% {
      opacity: 1;
      -webkit-transform: translate3d(100px, 0, 0);
      transform: translate3d(100px, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

// charts
.pieChart {
  width: 100%;
  height: 23rem;
}

.loading-dot {
  width: 11px;
  height: 11px;
  border: 2px solid $site-nav-toggle-color;
  border-radius: 50%;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
  &.active {
    background: $site-nav-toggle-color;
  }
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 400%;
    height: 400%;
    box-sizing: border-box;
    margin-left: -150%;
    margin-top: -150%;
    border-radius: 100%;
    background-color: $site-nav-toggle-color;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
}

.loading-dot-white {
  width: 11px;
  height: 11px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
  &.active {
    background: #fff;
  }
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 400%;
    height: 400%;
    box-sizing: border-box;
    margin-left: -150%;
    margin-top: -150%;
    border-radius: 100%;
    background-color: #fff;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
}

.radialWrapper {
  background: #000;
  width: 100%;
  height: 128px;
  position: absolute;
  bottom: 20px;
  z-index: 999;
}

.text_label {
  letter-spacing: 1px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.pull-right-md {
  text-align: right;

  @media (max-width: 768px) {
    text-align: left;
  }
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.markercluster-map {
  height: 90%;
}
.markercluster-map-full {
  height: 100%;
}
.marker-cluster-custom {
  background: $brand-primary;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: $brand-primary;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.react-confirm-alert-overlay {
  z-index: 99999 !important;
}
// ---------- END ---------

.toast-info {
  background-color: #2F96B4;
}
.toast-success {
  background-color: #51A351;
}
.toast-error{
  background-color: #BD362F;
}

.btn-close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 8px 0;
  font-size: 12px;
  border: none;
  outline: none !important;
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
}
.btn-close::before {
  content: '\f00d';
}
.btn-close [aria-hidden] {
  display: none;
}

.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// .leaflet-tile-container img {
//   box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
// }

.leaflet-tile[style*="width: 256px"] {
  width: 256.5px !important;
  height: 256.5px !important;
}

.leaflet-tile[style*="width: 512px"] {
  width: 512.5px !important;
  height: 512.5px !important;
}

.leaflet-pan-anim .leaflet-tile[style*="width: 256px"],
.leaflet-zoom-anim .leaflet-tile[style*="width: 256px"] {
  width: 256.5px !important;
  height: 256.5px !important;
}

.leaflet-pan-anim .leaflet-tile[style*="width: 512px"],
.leaflet-zoom-anim .leaflet-tile[style*="width: 512px"] {
  width: 512.5px !important;
  height: 512.5px !important;
}